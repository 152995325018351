import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { IconPerson } from 'icons';

interface Props {
  src?: string;
  name?: string;
  theme?: 'light' | 'dark';
}

export default function Avatar({ src, name, theme = 'dark' }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const timer = useRef<number | null>(null);
  const [width, setWidth] = useState(-1);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      setWidth(wrapperRef.current?.clientWidth || 0);
    }, 150);
  }, []);

  const initials = name
    ?.split(' ')
    .slice(0, 2)
    .map((s) => s.replace(/^[^a-zA-z\\?]+/, '').substring(0, 1))
    .join('')
    .toUpperCase();

  return (
    <div
      className={`relative rounded-full w-full h-full outline outline-1 ${
        theme === 'dark'
          ? 'outline-none hover:outline-white/10'
          : 'outline-black/10 hover:outline-black/10'
      } outline-offset-0 hover:outline-4`}
      ref={wrapperRef}
    >
      <div className="bg-grey5 rounded-full w-full h-full">
        {!!src && (
          <img
            src={src}
            alt=""
            className={cx('absolute z-10 inset-0 rounded-full object-cover', {
              hidden: !isLoaded,
              block: isLoaded,
            })}
            onLoad={() => setIsLoaded(true)}
          />
        )}

        {!!initials && !!width ? (
          <div
            className="flex items-center justify-center w-full h-full text-white font-bold"
            style={{ fontSize: `${Math.round(width * 0.45)}px` }}
          >
            {initials}
          </div>
        ) : (
          <div className="p-1">
            <IconPerson className="block w-full h-full text-white" />
          </div>
        )}
      </div>
    </div>
  );
}
