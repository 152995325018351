import Tooltip from 'components/Tooltip';
import { createElement } from 'react';

interface Props {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  disabled?: Boolean;
}

export default function FanSubmissionBulkAction(props: Props) {
  const { title, icon, onClick, disabled } = props;
  return (
    <Tooltip theme="primaryDark" arrow placement="top" content={title}>
      <button
        className="w-4.5 h-4.5 p-1 rounded-full hover:bg-grey8 disabled:opacity-50"
        disabled={!!disabled}
        onClick={onClick}
        title={title}
      >
        {createElement(icon, {
          className: 'block w-full h-full text-dark',
        })}
      </button>
    </Tooltip>
  );
}
