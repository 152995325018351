import LogInForm from 'components/LogInForm';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  email: string;
  onSignUp: () => void;
}

export default function SignUpLogInForm(props: Props) {
  const { email, onSignUp } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-2">
        <h1 className="h2 tablet:h1">{t('onboarding.signUp.logIn.heading')}</h1>
      </div>
      <LogInForm
        renderError={(_, accountExists) => (
          <div className="notice notice--error">
            {accountExists ? (
              t('onboarding.signUp.logIn.invalidPassword')
            ) : (
              <Trans
                i18nKey="onboarding.signUp.logIn.noUser"
                components={{
                  btn: <button className="underline" onClick={onSignUp} />,
                }}
              />
            )}
          </div>
        )}
        initialValues={{ contactValue: email }}
      />
      <div className="text-center mt-2">
        <button
          className="text-brandPrimary hover:underline text-14"
          onClick={onSignUp}
        >
          {t('onboarding.signUp.logIn.signUp')}
        </button>
      </div>
    </>
  );
}
