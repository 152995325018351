import Form from 'components/Form';
import omit from 'lodash/omit';
import camelize from 'camelize';
import isEmpty from 'lodash/isEmpty';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import useFlashMessages from 'hooks/useFlashMessages';
import Estimate from 'types/Estimate';
import { useCreateSubscriptionMutation } from 'api/subscriptions';
import SubscriptionParams from 'types/SubscriptionParams';
import { useAppSelector } from 'redux/store';
import { selectCurrentUser } from 'redux/reducers/auth';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import useTranslateErrors from 'hooks/useTranslateErrors';
import useTokenizeCard from 'hooks/useTokenizeCard';

interface Props {
  estimate: Estimate;
  company?: string;
}

type Values = Omit<SubscriptionParams, 'token' | 'planId'>;

interface Errors extends ValidationErrorPayload<Values> {
  card?: string[] | null;
}

export default function SignUpPaymentForm(props: Props) {
  const { estimate } = props;
  const { t } = useTranslation();
  const cardRef = useRef<any>(null);
  const currentUser = useAppSelector(selectCurrentUser);
  const translateErrors = useTranslateErrors();
  const [values, setValues] = useState<Values>({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    zip: '',
    company: props.company || '',
  });
  const [errors, setErrors] = useState<Errors | null>(null);
  const [tokenizeCard, { isTokenizing }] = useTokenizeCard(cardRef);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();

  const validateValues = () => {
    setErrors(null);
    const requiredFields = Object.keys(values) as Array<keyof typeof values>;
    return requiredFields.reduce((sum, field) => {
      if (!values[field]) return { ...sum, [field]: ['validation.required'] };
      return sum;
    }, {});
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const { addFlashMessage } = useFlashMessages();

  const handleCreateSubscription = async (token: string) => {
    const result = await createSubscription({
      ...values,
      token,
      planId: estimate.planId,
    });
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        window.scroll({ top: 0, behavior: 'smooth' });
        setErrors(camelize(result.error.data.errors));
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
      }
    } else {
      window.location.href = '/';
    }
  };

  const handleSubmit = async () => {
    if (!cardRef.current) return;

    // Client Side Validation
    const nextErrors = validateValues();
    if (!isEmpty(nextErrors)) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setErrors(nextErrors);
      return;
    }

    tokenizeCard({
      billingZip: values.zip,
      onSuccess: handleCreateSubscription,
    });
  };

  const handleChangeCard = (status: {
    complete: boolean;
    error?: { message: string };
  }) => {
    setIsCardComplete(status.complete);
    setErrors((errors) =>
      errors == null && !status.error
        ? null
        : {
            ...errors,
            card: status.error ? [status.error.message] : null,
          }
    );
  };

  const hasError =
    !!errors && Object.values(omit(errors, 'card')).some((e) => !!e);

  return (
    <div className="p-4 bg-white">
      <h3 className="h3 mb-2">{t(`onboarding.signUp.payment.heading`)}</h3>
      <div className="text-dark text-14 border-b-default pb-3 mb-3">
        {t(`onboarding.signUp.payment.description`)}
      </div>

      <div className="text-dark mb-3 font-bold">{estimate.description}</div>

      {hasError && (
        <div className="notice notice--error">
          {errors?.payment
            ? errors.payment
            : t('onboarding.signUp.payment.generalError')}
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        {!props.company && (
          <Form.TextInput
            value={values.company}
            name="company"
            label={t(`onboarding.signUp.payment.company`)}
            errors={translateErrors(errors?.company)}
            onChange={handleChange}
          />
        )}

        {(!currentUser?.firstName || !currentUser?.lastName) && (
          <div className="flex items-start mb-3 space-x-2">
            <div className="flex-1">
              <Form.TextInput
                value={values.firstName}
                name="firstName"
                label={t(`onboarding.signUp.payment.firstName`)}
                errors={translateErrors(errors?.firstName)}
                onChange={handleChange}
              />
            </div>

            <div className="flex-1">
              <Form.TextInput
                value={values.lastName}
                name="lastName"
                label={t(`onboarding.signUp.payment.lastName`)}
                errors={translateErrors(errors?.lastName)}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        <Form.Field
          label={t(`onboarding.signUp.payment.card`)}
          errors={errors?.card}
        >
          <div>
            <CardComponent
              ref={cardRef}
              onChange={handleChangeCard}
              fonts={['https://use.typekit.net/gcd4qci.css']}
              styles={{
                base: {
                  fontFamily: 'Outfit',
                  fontSize: '16px',
                  color: '#101820',
                  '::placeholder': {
                    color: '#9198A1',
                  },
                },
              }}
              options={{
                hidePostalCode: true,
                style: {},
              }}
            />
          </div>
        </Form.Field>

        <Form.TextInput
          value={values.zip}
          name="zip"
          label={t(`onboarding.signUp.payment.zip`)}
          errors={translateErrors(errors?.zip)}
          onChange={handleChange}
        />

        <button
          type="submit"
          className="btn btn--primary mb-2"
          data-loading={isLoading || isTokenizing}
          disabled={
            isLoading ||
            isTokenizing ||
            !isCardComplete ||
            !!errors?.card?.length
          }
        >
          {t(`onboarding.signUp.payment.submit`)}
        </button>

        <div className="text-12 px-3 text-center">
          <Trans
            i18nKey="onboarding.signUp.payment.subscriptionAgreement"
            components={{
              saLink: (
                /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                <a
                  href="https://www.socialie.com/subscription-agreement/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="text-dark hover:underline"
                />
              ),
            }}
          />
        </div>
      </Form>
    </div>
  );
}
