import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFetchCurrentUserQuery } from 'api/auth';
import { useFetchPlanQuery } from 'api/plan';
import ErrorPage from 'components/ErrorPage';
import LoadingPage from 'components/LoadingPage';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import usePlan from 'hooks/usePlan';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import ErrorFallback from './components/ErrorFallback';
import FlashMessages from './components/FlashMessages';
import GlobalSidebarNav from 'components/GlobalSidebarNav';
import FanRequests from 'screens/FanRequests';
import Auth from 'screens/Auth';
import Settings from 'screens/Settings';
import Invitation from 'screens/Invitation';
import SignUp from 'screens/Auth/screens/SignUp';
export default function Layout() {
  const { isLoading, error } = useFetchCurrentUserQuery();
  const { isLoading: isLoadingPlan } = useFetchPlanQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const plan = usePlan();

  if (import.meta.env.REACT_APP_ENABLE_SENTRY === 'true' && currentUser) {
    Sentry.setUser({
      id: currentUser.id.toString(),
      name: currentUser.name,
    });

    const currentFanAccount = currentUser.currentFanAccount;

    if (currentFanAccount) {
      Sentry.setTag('fan_account.id', currentFanAccount.id);
      Sentry.setTag('fan_account.name', currentFanAccount.name);
      Sentry.setTag('fan_account.brand', currentFanAccount.brand);
    }
  }

  const [isGlobalSidebarNavOpen, setIsGlobalSidebarNavOpen] = useState(false);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <div>
          <Helmet title="Socialie Fan" />
          <FlashMessages />
          {isLoading || isLoadingPlan ? (
            <LoadingPage />
          ) : error ? (
            <ErrorPage />
          ) : currentUser && currentUser.currentFanAccount ? (
            <>
              {!plan.isActive ? (
                <Switch>
                  <Route path={routes.settings.root}>
                    <Settings
                      renderNav={() => (
                        <GlobalSidebarNav
                          isOpen={isGlobalSidebarNavOpen}
                          handleOnOpen={setIsGlobalSidebarNavOpen}
                        />
                      )}
                    />
                  </Route>

                  {/*
                      Allow a user on an expired plan to sign up for a new
                      account if they want to
                    */}
                  <Route path={routes.auth.signUp}>
                    <SignUp />
                  </Route>
                  <Redirect to={routes.settings.subscription} />
                </Switch>
              ) : (
                <Switch>
                  <Route path={routes.invitation}>
                    <Invitation currentUser={currentUser} />
                  </Route>

                  <Route path={routes.auth.signUp}>
                    <SignUp />
                  </Route>

                  <Route>
                    <Switch>
                      <Route
                        path={[routes.requests.show, routes.requests.index]}
                      >
                        <FanRequests
                          renderNav={() => (
                            <GlobalSidebarNav
                              isOpen={isGlobalSidebarNavOpen}
                              handleOnOpen={setIsGlobalSidebarNavOpen}
                            />
                          )}
                        />
                      </Route>

                      <Route path={routes.settings.root}>
                        <Settings
                          renderNav={() => (
                            <GlobalSidebarNav
                              isOpen={isGlobalSidebarNavOpen}
                              handleOnOpen={setIsGlobalSidebarNavOpen}
                            />
                          )}
                        />
                      </Route>

                      <Redirect to={routeFor(routes.requests.index, 'live')} />
                    </Switch>
                  </Route>
                </Switch>
              )}
            </>
          ) : (
            <Auth currentUser={currentUser} />
          )}
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
