import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';
import { IconClose } from 'icons';

export default function UpsellPromo() {
  const { t } = useTranslation();
  const [isPromoUpsellCookieSet, setIsPromoUpsellCookieSet] = useState(
    cookie.load('additional_apps_promo_seen')
  );

  if (isPromoUpsellCookieSet) return null;

  function handlePromoClose(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setIsPromoUpsellCookieSet(true);
    cookie.save('additional_apps_promo_seen', 'true', { path: '/' });
  }

  return (
    <div className="bg-brandPrimaryOnDark text-white text-12 rounded-lg p-1 relative -mx-1.5 mb-2">
      <div className="flex justify-end">
        <button onClick={handlePromoClose}>
          <IconClose className="h-2 w-2" />
        </button>
      </div>

      <h4 className="-mt-2.5 pb-1 font-semibold">
        {t('globalMenu.promo.title')}
      </h4>
      <p className="text-grey5">{t('globalMenu.promo.body')}</p>
    </div>
  );
}
