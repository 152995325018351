import { useCancelSubscriptionMutation } from 'api/subscriptions';
import Modal from 'components/Modal';
import formatDate from 'helpers/formatDate';
import useFlashMessages from 'hooks/useFlashMessages';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';

interface Props {
  subscription: Subscription;
}

export default function SettingsSubscriptionPlanCancel(props: Props) {
  const { subscription } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();
  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();

  const handleCancel = async () => {
    const result = await cancelSubscription();
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    }
  };

  return (
    <>
      <button
        className="btn btn--grey btn--inlineBlock btn--small"
        onClick={() => setIsModalOpen(true)}
      >
        {t('settings.subscription.plan.cancel')}
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        renderHeading={t('settings.subscription.plan.cancelModal.heading')}
        renderActions={
          <>
            <button
              className="btn btn--grey"
              onClick={() => setIsModalOpen(false)}
            >
              {t('settings.subscription.plan.cancelModal.cancel')}
            </button>

            <button
              className="btn btn--primary"
              data-loading={isLoading}
              disabled={isLoading}
              onClick={handleCancel}
            >
              {t('settings.subscription.plan.cancelModal.continue')}
            </button>
          </>
        }
      >
        {t('settings.subscription.plan.cancelModal.body', {
          termEnd: formatDate(
            subscription.currentTermEnd || subscription.trialEnd || -1,
            {
              time: false,
              forceYear: true,
              month: 'long',
            }
          ),
        })}
      </Modal>
    </>
  );
}
