import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'FanAccount',
    'FanAccountUser',
    'FanDomain',
    'FanRequest',
    'Subscription',
    'FanPlan',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.REACT_APP_API_SERVER_URL,
    credentials: 'include',
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    { status: number; data: { errors: any } },
    {}
  >,
  endpoints: () => ({}),
});

export default api;
