import { useFetchInvoicesQuery, useLazyFetchInvoiceQuery } from 'api/invoices';
import formatDate from 'helpers/formatDate';
import useFlashMessages from 'hooks/useFlashMessages';
import { IconDownload, IconLoader } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsSubscriptionSection from './SettingsSubscriptionSection';

export default function SettingsSubscriptionInvoices() {
  const { data: invoices, isLoading, error } = useFetchInvoicesQuery();
  const { t } = useTranslation();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <SettingsSubscriptionSection
      heading={t('settings.subscription.invoices.heading')}
    >
      <div className="mt-1">
        {isLoading ? (
          <IconLoader className="w-5 h-5 text-brandPrimary" />
        ) : error || !invoices ? (
          <div className="notice notice--error inline-block">
            {t('settings.subscription.invoices.error')}
          </div>
        ) : !invoices.length ? (
          <div className="text-dark text-14">
            {t('settings.subscription.invoices.none')}
          </div>
        ) : (
          <>
            {invoices.map((invoice) => (
              <div
                key={invoice.id}
                className="flex items-center text-dark text-14 mb-0.5"
              >
                <div className="flex-1">
                  {formatDate(invoice.date, {
                    time: false,
                    forceYear: true,
                    month: 'long',
                  })}
                </div>
                <div className="flex-1">
                  {formatter.format(invoice.total / 100)}
                </div>
                <div className="flex-shrink-0 ml-auto">
                  <DownloadInvoice id={invoice.id} />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </SettingsSubscriptionSection>
  );
}

function DownloadInvoice({ id }: { id: string }) {
  const [fetchInvoice, { data, error, isLoading }] = useLazyFetchInvoiceQuery();
  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();

  const url = data?.url;

  // Open the URL
  useEffect(() => {
    if (url) window.open(url);
  }, [url]);

  // Show an error
  useEffect(() => {
    if (!error) return;
    addFlashMessage(t('global.unexpectedError'), { isError: true });
  }, [error, addFlashMessage, t]);

  const Icon = isLoading ? IconLoader : IconDownload;

  return (
    <button
      className="text-brandPrimary hover:underline"
      onClick={() => fetchInvoice(id)}
      disabled={isLoading}
    >
      <span className="flex items-center">
        <Icon className="w-2 h-2 mr-0.5" />
        <span className="text-12 font-bold">
          {t('settings.subscription.invoices.pdf')}
        </span>
      </span>
    </button>
  );
}
