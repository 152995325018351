import cx from 'classnames';
import FanRequestDeleteModal from 'components/FanRequestDeleteModal';
import StatusPill from 'components/StatusPill';
import Tooltip from 'components/Tooltip';
import { AnimatePresence, motion } from 'framer-motion';
import getTypeFromFanRequest from 'helpers/getTypeFromFanRequest';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import { IconClose, IconLoader, IconTrash } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import FanRequest from 'types/FanRequest';
import FanRequestParams from 'types/FanRequestParams';
import { FanRequestFormSaveOptions } from '../FanRequestForm';
import SaveAndPreviewModal from './SaveAndPreviewModal';

interface Props {
  hasUnsavedChanges: boolean;
  fanRequest: FanRequest;
  values: FanRequestParams;
  context: 'builder' | 'edit';
  onSave: (options?: FanRequestFormSaveOptions) => void;
  saveTriggeredBy?: FanRequestFormSaveOptions['trigger'];
  disableUnsavedChangesPrompt: () => void;
  activeUploadCount: number;
}

export default function FanRequestFormTopBar(props: Props) {
  const {
    fanRequest,
    values,
    context,
    onSave,
    saveTriggeredBy,
    hasUnsavedChanges,
    disableUnsavedChangesPrompt,
    activeUploadCount,
  } = props;
  const isSaving = !!saveTriggeredBy;
  const { t } = useTranslation();
  const isLive = fanRequest.status === 'published';
  const history = useHistory();
  const [openModal, setOpenModal] = useState<'save+preview' | 'delete' | null>(
    null
  );

  const handleSaveAndClose = () => {
    onSave({
      onSuccess: () => history.push(routeFor(routes.requests.index, 'live')),
      trigger: 'save+close',
    });
  };

  const handlePublish = () => {
    onSave({
      values: { status: 'published' },
      onSuccess: () => {
        history.push(
          routeFor(
            routes.requests.show,
            getTypeFromFanRequest(fanRequest),
            fanRequest.id
          )
        );
      },
      trigger: 'publish',
    });
  };

  const openPreview = () => window.open(fanRequest.previewUrl, '_blank');

  const handleClickPreview = () => {
    if (hasUnsavedChanges) {
      setOpenModal('save+preview');
    } else {
      openPreview();
    }
  };

  return (
    <div
      className={cx('flex items-center px-3 h-7 border-b-default', {
        'bg-grey9': !isLive,
        'bg-brandPrimary': isLive,
      })}
    >
      <span
        className={cx('h4 truncate bump-up-2', {
          '!text-white': isLive,
        })}
      >
        {context === 'builder'
          ? t('fanRequestBuilder.heading')
          : fanRequest.subject || t('global.noSubject')}
      </span>

      <div className="ml-1 bump-down-2">
        <StatusPill
          fanRequest={fanRequest}
          theme={isLive ? 'inverted' : 'normal'}
        />
      </div>

      <div className="ml-1 mr-4">
        <AnimatePresence>
          {isLive ? (
            <span className="text-12 text-white font-bold whitespace-nowrap">
              {t('fanRequestBuilder.actions.editingLive')}
            </span>
          ) : hasUnsavedChanges ? (
            <motion.div
              className="flex items-center text-12 text-light whitespace-nowrap"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              {t('fanRequestBuilder.actions.unsavedChanges')}

              <button
                className="ml-1 text-grey4 underline hover:text-dark disabled:opacity-50"
                disabled={isSaving || !!activeUploadCount}
                onClick={() => onSave()}
              >
                {t('fanRequestBuilder.actions.saveNow')}
              </button>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>

      <div className="ml-auto flex items-center space-x-1.5">
        {!!activeUploadCount ? (
          <div
            className={cx(
              'flex items-center h-5 rounded-full bg-white pl-1.5 pr-2',
              isLive ? 'bg-white' : 'bg-grey8'
            )}
          >
            <IconLoader className="w-2.5 h-2.5 mr-1" />
            <span className="text-dark text-14 font-bold">
              {t('fanRequestBuilder.uploadInProgress', {
                count: activeUploadCount,
              })}
            </span>
          </div>
        ) : (
          <>
            {isLive ? (
              <>
                <button
                  className="btn btn--white"
                  onClick={handlePublish}
                  data-loading={saveTriggeredBy === 'publish'}
                  disabled={isSaving || !values.urlSlug}
                >
                  {t('fanRequestBuilder.actions.publishChanges')}
                </button>

                <Link
                  className="block flex-shrink-0 w-3 h-3 text-white"
                  to={routeFor(routes.requests.show, 'live', fanRequest.id)}
                  aria-label={t('fanRequestBuilder.actions.closeWithoutSaving')}
                >
                  <IconClose className="block w-full h-full" />
                </Link>
              </>
            ) : (
              <>
                <Tooltip
                  theme="primaryDark"
                  arrow
                  placement="top"
                  content={t('fanRequestBuilder.actions.delete')}
                >
                  <button
                    className="flex-shrink-0 block w-4.5 h-4.5 rounded-full p-1 hover:bg-grey8"
                    onClick={() => setOpenModal('delete')}
                    aria-label={t('fanRequestBuilder.actions.delete')}
                  >
                    <IconTrash className="block w-full h-full text-dark" />
                  </button>
                </Tooltip>

                {context === 'edit' ? (
                  <button
                    className="btn btn--grey"
                    onClick={() => onSave({ trigger: 'save-draft' })}
                    data-loading={saveTriggeredBy === 'save-draft'}
                    disabled={isSaving || !values.urlSlug}
                  >
                    {t('global.save')}
                  </button>
                ) : (
                  <button
                    className="btn btn--grey"
                    onClick={handleSaveAndClose}
                    data-loading={saveTriggeredBy === 'save+close'}
                    disabled={isSaving || !values.urlSlug}
                  >
                    {t('fanRequestBuilder.actions.saveAndClose')}
                  </button>
                )}

                <button
                  className="btn btn--grey"
                  onClick={handleClickPreview}
                  disabled={isSaving || !values.urlSlug}
                >
                  {t('fanRequestBuilder.actions.preview')}
                </button>

                <button
                  className="btn btn--primary"
                  onClick={handlePublish}
                  data-loading={saveTriggeredBy === 'publish'}
                  disabled={isSaving || !values.urlSlug}
                >
                  {t('fanRequestBuilder.actions.publish')}
                </button>

                <Link
                  className="block flex-shrink-0 w-3 h-3 text-light"
                  to={routeFor(routes.requests.index, 'live')}
                  aria-label={t('fanRequestBuilder.actions.closeWithoutSaving')}
                >
                  <IconClose className="block w-full h-full" />
                </Link>
              </>
            )}
          </>
        )}
      </div>

      <SaveAndPreviewModal
        isOpen={openModal === 'save+preview'}
        onRequestClose={() => setOpenModal(null)}
        onSave={() => {
          onSave({
            trigger: 'save+preview',
            onSuccess: () => {
              setOpenModal(null);
              setTimeout(() => openPreview(), 500);
            },
          });
        }}
        isSaving={saveTriggeredBy === 'save+preview'}
      />

      {!isLive && (
        <FanRequestDeleteModal
          fanRequest={fanRequest}
          isOpen={openModal === 'delete'}
          onRequestClose={() => setOpenModal(null)}
          onSuccess={() => {
            disableUnsavedChangesPrompt();
            history.push(
              routeFor(routes.requests.index, getTypeFromFanRequest(fanRequest))
            );
          }}
        />
      )}
    </div>
  );
}
