import { IconUGCLogo } from 'icons';

interface Props {
  heading?: string;
  description?: React.ReactNode | string;
  notice?: React.ReactNode | string;
  children: React.ReactNode;
}

export default function AuthPage(props: Props) {
  const { heading, description, notice, children } = props;

  return (
    <div className="w-screen h-screen overflow-hidden flex items-center justify-center relative bg-white">
      <div className="absolute top-0 left-0 px-1 tablet:px-2 py-1 tablet:py-3 z-50">
        <div className="p-1 bg-white rounded w-26.5 h-9">
          <IconUGCLogo className="w-full h-full" />
        </div>
      </div>
      <div className="tablet:mb-2 desktop:mb-5 hidden tablet:block absolute bottom-0 tablet:-left-58 desktop:-left-51">
        <div className="w-72 h-72 bg-transparent border-12 border-solid border-brandPrimary rounded-full" />
      </div>
      <div className="mt-14 hidden tablet:block absolute top-0 tablet:-right-36 desktop:-right-34">
        <div className="w-45 h-45 bg-socialieBlue rounded-full" />
      </div>
      <div className="mx-2 max-w-58 tablet:w-58">
        <div className="mb-4">
          <h2 className="h2">{heading}</h2>
          {!!description && <div className="mt-2">{description}</div>}
          {!!notice && <div className="mt-2">{notice}</div>}
        </div>
        {children}
      </div>
    </div>
  );
}
