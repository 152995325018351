import cx from 'classnames';
import Tooltip from 'components/Tooltip';
import { IconCheck, IconLock, IconTime, IconWarning } from 'icons';
import { useTranslation } from 'react-i18next';
import Domain from 'types/Domain';
import { useHistory } from 'react-router';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';

interface Props {
  domain: Domain;
}

export default function SettingsDomainsSSL({ domain }: Props) {
  const { t } = useTranslation();
  const { sslOwnershipVerified, sslCertificateInstalled } = domain;
  const history = useHistory();

  const msg = sslCertificateInstalled
    ? 'settings.domains.ssl.tooltipComplete'
    : sslOwnershipVerified
    ? 'settings.domains.ssl.tooltipVerified'
    : 'settings.domains.ssl.tooltipIncomplete';

  return (
    <Tooltip content={t(msg)} theme="primaryDark" placement="top" arrow>
      <div>
        <button
          disabled={sslCertificateInstalled}
          className="relative w-4 h-4 p-0.25"
          onClick={() =>
            history.push(routeFor(routes.settings.domains.ssl, domain.id))
          }
        >
          <IconLock className="text-lightIcon block w-full h-full" />
          <span
            className={cx('absolute bottom-0 right-0 w-2 h-2 rounded-full', {
              'bg-success p-[3px] border border-solid border-white':
                sslCertificateInstalled,
              'bg-white text-brightBlue':
                sslOwnershipVerified && !sslCertificateInstalled,
            })}
          >
            {sslCertificateInstalled ? (
              <IconCheck className="w-full h-full text-white" />
            ) : sslOwnershipVerified ? (
              <IconTime className="w-full h-full" />
            ) : (
              <IconWarning className="text-yellow bg-white rounded-full w-full h-full" />
            )}
          </span>
        </button>
      </div>
    </Tooltip>
  );
}
