import FanRequestType from 'types/FanRequestType';
import {
  IconLiveFancy,
  IconDraftFancy,
  IconExpiredFancy,
  IconPlus,
} from 'icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import NewRequestButton from 'components/NewRequestButton';
import routeFor from 'helpers/routeFor';

interface Props {
  type: FanRequestType;
}

const icons: Record<FanRequestType, typeof IconLiveFancy> = {
  live: IconLiveFancy,
  draft: IconDraftFancy,
  expired: IconExpiredFancy,
};

export default function FanRequestsNoResults({ type }: Props) {
  const { t } = useTranslation();

  return (
    <div className="w-full flex-1 flex-v-center">
      <div className="mx-auto text-center">
        {React.createElement(icons[type], {
          className: 'mb-2 mx-auto w-6 h-6 text-dark',
        })}

        <h5 className="mb-4 font-bold text-dark text-20">
          {t(`fanRequests.noResults.${type}`)}
        </h5>

        {type === 'expired' ? (
          <Link
            className="btn btn--grey btn--inlineBlock"
            to={routeFor(routes.requests.index, 'live')}
          >
            {t('fanRequests.noResults.viewLive')}
          </Link>
        ) : (
          <NewRequestButton
            renderButton={(onClick, isLoading) => (
              <button
                className="btn btn--grey btn--inlineBlock"
                onClick={onClick}
                data-loading={isLoading}
                disabled={isLoading}
              >
                <span className="flex items-center">
                  <IconPlus />
                  <span>{t('fanRequests.noResults.createNew')}</span>
                </span>
              </button>
            )}
          />
        )}
      </div>
    </div>
  );
}
