import routes from 'helpers/routes';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import {
  SettingsBrand,
  SettingsProfile,
  SettingsSubscription,
  SettingsDomains,
  SettingsUsers,
} from './screens';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import usePlan from 'hooks/usePlan';

interface SettingsProps {
  renderNav: () => JSX.Element | null;
}

export default function Settings(props: SettingsProps) {
  const { renderNav } = props;
  const { t } = useTranslation();
  const plan = usePlan();

  return (
    <>
      <Helmet title={t('settings.pageTitle')} />{' '}
      <div className="flex flex-col">
        <div className="grid-col-56px-auto">
          <div>{renderNav && renderNav()}</div>
          <div>
            <div className="w-full bg-white with-topbar">
              {!plan.isActive ? (
                <Switch>
                  <Route path={routes.settings.subscription}>
                    <SettingsSubscription />
                  </Route>
                  <Redirect to={routes.settings.subscription} />
                </Switch>
              ) : (
                <Switch>
                  <Route path={routes.settings.brand}>
                    <SettingsBrand />
                  </Route>
                  <Route path={routes.settings.users}>
                    <SettingsUsers />
                  </Route>

                  <Route path={routes.settings.subscription}>
                    <SettingsSubscription />
                  </Route>

                  <Route path={routes.settings.profile}>
                    <SettingsProfile />
                  </Route>

                  <Route path={routes.settings.domains.root}>
                    <SettingsDomains />
                  </Route>

                  <Redirect to={routes.settings.profile} />
                </Switch>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
