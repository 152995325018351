import { Link, LinkProps as RRLinkProps } from 'react-router-dom';
import cx from 'classnames';
import React from 'react';
import castArray from 'lodash/castArray';

type Variant = 'small' | 'inlineBlock' | 'rounded';

interface BaseProps {
  color?:
    | 'primary'
    | 'grey'
    | 'white'
    | 'darkGrey'
    | 'outline'
    | 'error'
    | 'destructive';
  variant?: Variant | Variant[];
  leftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
}

interface ButtonProps
  extends BaseProps,
    Omit<React.ComponentProps<'button'>, 'color'> {}

interface AProps extends BaseProps, Omit<React.ComponentProps<'a'>, 'color'> {}

interface LinkProps extends BaseProps, Omit<RRLinkProps, 'color'> {}

type Props = ButtonProps | AProps | LinkProps;

export default function Btn(props: Props) {
  const {
    color = 'primary',
    variant,
    leftIcon,
    rightIcon,
    children,
    className,
    isLoading,
    ...restProps
  } = props;

  const classNames = cx(
    'btn',
    `btn--${color}`,
    castArray(variant).map((t) => `btn--${t}`),
    className
  );

  const innards = (
    <span className="flex items-center">
      {leftIcon && React.createElement(leftIcon)}
      <span>{children}</span>
      {rightIcon && React.createElement(rightIcon)}
    </span>
  );

  return 'to' in restProps ? (
    <Link className={classNames} data-loading={isLoading} {...restProps}>
      {innards}
    </Link>
  ) : 'href' in restProps ? (
    <a className={classNames} data-loading={isLoading} {...restProps}>
      {innards}
    </a>
  ) : (
    <button
      className={classNames}
      data-loading={isLoading}
      {...(restProps as React.ComponentProps<'button'>)}
    >
      {innards}
    </button>
  );
}
