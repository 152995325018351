import { IconErrorPage } from 'icons';
import { useTranslation } from 'react-i18next';

export default function SettingsSubscriptionError() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-stretch w-full bg-white min-h-screen">
      <div className="w-full flex-1 flex-v-center">
        <div className="mx-auto text-center px-2">
          <div className="mx-auto w-12 h-12 mb-4.5">
            <IconErrorPage className="w-full h-full" />
          </div>

          <h4 className="h4 mb-2 px-2">{t('settings.subscription.error')}</h4>

          <a href="/" className="btn btn--primary btn--inlineBlock">
            {t('fanRequestError.dashboard')}
          </a>
        </div>
      </div>
    </div>
  );
}
