import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import Modal from 'components/Modal';
import Form from 'components/Form';
import useUpdateProfile from 'hooks/useUpdateProfile';
import useFlashMessages from 'hooks/useFlashMessages';
import SettingsProfileSection from './SettingsProfileSection';
import useTranslateErrors from 'hooks/useTranslateErrors';

interface Props {
  currentUser: User;
}

export default function SettingsProfileEmail(props: Props) {
  const { currentUser } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(currentUser.email || '');
  const { addFlashMessage } = useFlashMessages();

  const [updateProfile, { isLoading, errors }] = useUpdateProfile({
    onSuccess: () => {
      addFlashMessage(t('settings.profile.email.success'));
      setIsOpen(false);
    },
  });

  const translateErrors = useTranslateErrors();

  const handleCancel = () => {
    setValue(currentUser.email || '');
    setIsOpen(false);
  };

  const handleSubmit = () => {
    updateProfile({ email: value });
  };

  return (
    <>
      <SettingsProfileSection
        label={t('settings.profile.email.label')}
        onClick={() => setIsOpen(true)}
      >
        <div className="text-dark text-14">{currentUser.email}</div>
      </SettingsProfileSection>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t('settings.profile.email.modal.heading')}
        renderActions={
          <>
            <button className="btn btn--grey" onClick={handleCancel}>
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--primary"
              onClick={handleSubmit}
              disabled={isLoading || !value}
              data-loading={isLoading}
            >
              {t('global.save')}
            </button>
          </>
        }
      >
        <Form onSubmit={handleSubmit}>
          <Form.TextInput
            label={t('settings.profile.email.modal.label')}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            errors={translateErrors(errors?.email)}
          />
        </Form>
      </Modal>
    </>
  );
}
