import { IconSend } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import Form from 'components/Form';
import { useInviteAccountUserMutation } from 'api/fanAccountUser';
import useFlashMessages from 'hooks/useFlashMessages';
import SettingsUsersPermissionsTooltip from './SettingsUsersPermissionsTooltip';
import usePlan from 'hooks/usePlan';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function SettingsUsersInviteUserModal(props: Props) {
  const { isOpen, onRequestClose } = props;
  const [values, setValues] = useState({ email: '', role: '' });
  const [errors, setErrors] = useState<string | null>(null);
  const { t } = useTranslation();
  const [inviteAccountUser, { isLoading }] = useInviteAccountUserMutation();
  const { addFlashMessage } = useFlashMessages();

  const roleOptions = [
    { label: t('settings.users.invite.roles.placeholder'), value: '' },
    {
      label: t('settings.users.roles.member.label'),
      value: 'member',
    },
    {
      label: t('settings.users.roles.owner.label'),
      value: 'owner',
    },
  ];

  const selectedRole = roleOptions.find((o) => o.value === values.role);

  const handleSubmit = async () => {
    setErrors(null);
    const result = await inviteAccountUser(values);
    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(result.error.data.errors);
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
      }
    } else {
      addFlashMessage(
        t('settings.users.invite.success', { email: values.email })
      );
      handleClose();
    }
  };

  const handleClose = () => {
    setValues({ email: '', role: '' });
    setErrors(null);
    onRequestClose();
  };

  const plan = usePlan();
  const limitReached = plan.maxUsers && plan.usage.userCount >= plan.maxUsers;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      renderHeading={t('settings.users.invite.heading')}
      renderActions={
        <>
          <button className="btn btn--grey" onClick={handleClose}>
            {t('global.cancel')}
          </button>

          {!limitReached && (
            <button
              className="btn btn--primary"
              onClick={handleSubmit}
              disabled={isLoading || !values.email || !values.role}
            >
              <span className="flex items-center">
                <IconSend />
                <span>{t('settings.users.invite.submit')}</span>
              </span>
            </button>
          )}
        </>
      }
    >
      {limitReached ? (
        <div>{t('settings.users.invite.limitReached')}</div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.TextInput
            type="email"
            label={t('settings.users.invite.email.label')}
            errors={errors ? t(errors) : undefined}
            placeholder={t('settings.users.invite.email.placeholder')}
            value={values.email}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />

          <Form.Select
            label={
              <span className="flex items-center">
                {t('settings.users.invite.role.label')}
                <span className="ml-0.5 bump-down-1">
                  <SettingsUsersPermissionsTooltip />
                </span>
              </span>
            }
            value={selectedRole}
            options={roleOptions}
            onChange={(v) => setValues({ ...values, role: v?.value || '' })}
          />
        </Form>
      )}
    </Modal>
  );
}
