import { useAcceptInvitationMutation } from 'api/invitations';
import Form from 'components/Form';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Invitation from 'types/Invitation';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import camelize from 'camelize';
import useFlashMessages from 'hooks/useFlashMessages';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { useHistory } from 'react-router-dom';

interface Props {
  code: string;
  invitation: Invitation;
}

const defaultValues = {
  password: '',
  passwordConfirmation: '',
};

export default function InvitationNewUser(props: Props) {
  const { code, invitation } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultValues);
  const [acceptInvitation, { isLoading }] = useAcceptInvitationMutation();
  const [errors, setErrors] = useState<ValidationErrorPayload<
    typeof values
  > | null>(null);
  const { addFlashMessage } = useFlashMessages();
  const translateErrors = useTranslateErrors();

  const handleSubmit = async () => {
    setErrors(null);
    const result = await acceptInvitation({ code, ...values });

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        setErrors(camelize(result.error.data.errors));
      } else {
        addFlashMessage(t('global.unexpectedError'), { isError: true });
      }
    } else {
      history.push('/');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <p className="mb-3">{t('invitation.newUser.description')}</p>

      <Form.TextInput
        value={invitation.user.email}
        label={t('invitation.newUser.email')}
        disabled
      />

      <Form.TextInput
        label={t('invitation.newUser.password.label')}
        description={t('invitation.newUser.password.description')}
        value={values.password}
        type="password"
        errors={translateErrors(errors?.password)}
        onChange={(e) =>
          setValues((values) => ({ ...values, password: e.target.value }))
        }
      />

      <Form.TextInput
        label={t('invitation.newUser.passwordConfirmation.label')}
        value={values.passwordConfirmation}
        type="password"
        errors={translateErrors(errors?.passwordConfirmation)}
        onChange={(e) =>
          setValues((values) => ({
            ...values,
            passwordConfirmation: e.target.value,
          }))
        }
      />

      <button
        className="btn btn--primary"
        onClick={handleSubmit}
        disabled={isLoading || Object.values(values).includes('')}
        data-loading={isLoading}
      >
        {t('invitation.accept')}
      </button>

      <div className="text-12 mt-2 text-center">
        <Trans
          i18nKey="invitation.newUser.eula"
          components={{
            /* eslint-disable jsx-a11y/anchor-has-content */
            eulaLink: (
              <a
                href="https://www.socialie.com/terms-and-conditions/"
                className="text-brandPrimary hover:underline"
                target="_blank"
                rel="noreferrer"
              />
            ),
            ppLink: (
              <a
                href="https://www.socialie.com/privacy-policy/"
                className="text-brandPrimary hover:underline"
                target="_blank"
                rel="noreferrer"
              />
            ),
            /* eslint-enable jsx-a11y/anchor-has-content */
          }}
        />
      </div>
    </Form>
  );
}
