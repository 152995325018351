import cx from 'classnames';
import { IconClose } from 'icons';
import { createPortal } from 'react-dom';
import uniqueId from 'lodash/uniqueId';
import React, { createContext, useState, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useKey from '@rooks/use-key';

interface Props {
  isOpen?: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
  renderHeading?: React.ReactNode;
  renderActions?: React.ReactNode;
  contentClassNames?: string;
  containerClassNames?: string;
  theme?: 'classic' | 'large' | 'notification' | 'onboarding';
}

const ThemeContext = createContext<Props['theme']>('classic');

export default function Modal(props: Props) {
  const [id] = useState(uniqueId('modal-'));

  const {
    isOpen = true,
    onRequestClose,
    children,
    renderHeading,
    renderActions,
    contentClassNames,
    containerClassNames,
    theme = 'classic',
  } = props;

  useKey(['Escape'], onRequestClose);

  const handleClickOverlay: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target !== e.currentTarget) return;
    onRequestClose();
  };

  return createPortal(
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <motion.div
          id={`${id}-overlay`}
          key={`${id}-overlay`}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className="flex items-center justify-center fixed inset-0 z-modal bg-overlay bg-opacity-30"
          onClick={handleClickOverlay}
        >
          <ThemeContext.Provider value={theme}>
            {/* Actual Modal */}
            <div
              className={cx(
                'bg-white rounded-xl shadow-2xl relative',
                {
                  'w-76': theme === 'onboarding',
                  'w-67': theme === 'classic',
                  'w-41': theme === 'notification',
                  'w-80': theme === 'large',
                },
                containerClassNames
              )}
              id={id}
              role="dialog"
              aria-labelledby={`${id}-heading`}
            >
              {/* Heading and Close Button */}
              <div className="flex items-center p-4 pb-0 mb-2">
                <div
                  className={cx('bump-up-1', {
                    'h4 mr-2': ['classic', 'large'].includes(theme),
                    'h6 flex-1 text-center': theme === 'notification',
                    'h2 flex-1 text-center': theme === 'onboarding',
                  })}
                  id={`${id}-heading`}
                >
                  {renderHeading}
                </div>

                {['classic', 'large'].includes(theme) && (
                  <div className="ml-auto">
                    <button
                      onClick={onRequestClose}
                      className="w-3 h-3 bump-down-1"
                    >
                      <IconClose className="block w-full h-full text-light" />
                    </button>
                  </div>
                )}
              </div>

              {/* Content */}
              <div
                className={cx(
                  'pt-0',
                  {
                    'px-4 mb-4': ['classic', 'large'].includes(theme),
                    'px-3 text-center text-14 leading-20 mb-3': [
                      'notification',
                      'onboarding',
                    ].includes(theme),
                  },
                  contentClassNames
                )}
              >
                {children}

                {renderActions && <Actions>{renderActions}</Actions>}
              </div>
            </div>
          </ThemeContext.Provider>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
}

Modal.Actions = Actions;

type ActionsProps = React.ComponentProps<React.FC>;

function Actions({ children }: ActionsProps) {
  const theme = useContext(ThemeContext)!;

  return ['classic', 'large'].includes(theme) ? (
    <div className="flex items-center">
      <div className="flex-1" />
      <div className="ml-auto flex items-center justify-end space-x-1 mt-4 px-4">
        {children}
      </div>
    </div>
  ) : theme === 'notification' ? (
    <div className="flex flex-col px-4 pb-2.5 items-stretch space-y-1.5 mt-3">
      {children}
    </div>
  ) : theme === 'onboarding' ? (
    <div className="flex flex-col px-4 pb-2.5 items-center space-y-1.5 mt-3">
      {children}
    </div>
  ) : null;
}
