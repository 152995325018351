import { useAcceptInvitationMutation } from 'api/invitations';
import useFlashMessages from 'hooks/useFlashMessages';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  code: string;
}

export default function InvitationAccept(props: Props) {
  const { code } = props;
  const { t } = useTranslation();
  const [acceptInvitation, { isLoading }] = useAcceptInvitationMutation();
  const { addFlashMessage } = useFlashMessages();
  const history = useHistory();

  const handleAccept = async () => {
    const result = await acceptInvitation({ code });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <button
        className="btn btn--primary mb-2"
        onClick={handleAccept}
        disabled={isLoading}
        data-loading={isLoading}
      >
        {t('invitation.accept')}
      </button>

      <Link className="btn btn--grey mb-2" to="/">
        {t('invitation.ignore')}
      </Link>
    </>
  );
}
