import { useFetchDomainsQuery } from 'api/domains';
import Btn from 'components/Btn';
import ErrorPage from 'components/ErrorPage';
import { SecondaryTopBar } from 'components/Layout/components';
import LoadingPage from 'components/LoadingPage';
import routes from 'helpers/routes';
import usePlan from 'hooks/usePlan';
import { Route } from 'react-router-dom';
import { IconPlus } from 'icons';
import { useTranslation, Trans } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import {
  SettingsDomainsAddModal,
  SettingsDomainsBrandRow,
  SettingsDomainsDNSModal,
  SettingsDomainsRow,
  SettingsDomainsSSLModal,
} from './components';

export default function SettingsDomains() {
  const { currentFanAccount } = useAppSelector(selectCurrentUser)!;
  const plan = usePlan();
  const { t } = useTranslation();
  const {
    data: domains,
    isLoading,
    error,
  } = useFetchDomainsQuery(undefined, { skip: !plan.customDomains });
  return (
    <>
      <SecondaryTopBar
        heading={t('settings.domains.heading')}
        subHeading={t('settings.domains.subheading')}
      />

      <div className="bg-white max-w-100 mx-auto">
        <div className="pt-3">
          {plan.customDomains && (
            <div className="text-dark mb-4">
              {t('settings.domains.description')}
            </div>
          )}

          {isLoading ? (
            <LoadingPage />
          ) : error ? (
            <ErrorPage />
          ) : (
            <>
              <SettingsDomainsBrandRow brand={currentFanAccount.brand} />

              <div className="pb-4 mb-4 border-b-default last:border-b-0">
                <h4 className="h4 mb-0.5">
                  {t('settings.domains.customDomains')}
                </h4>

                {domains?.map((domain) => (
                  <SettingsDomainsRow key={domain.id} domain={domain} />
                ))}

                {plan.customDomains ? (
                  <div className="pt-2">
                    <Btn
                      to={routes.settings.domains.new}
                      variant="inlineBlock"
                      leftIcon={IconPlus}
                    >
                      {t('settings.domains.add')}
                    </Btn>
                  </div>
                ) : (
                  <div className="text-dark text-14">
                    <Trans
                      i18nKey="settings.domains.upgrade"
                      components={{
                        p: <p className="mb-1 last:mb-0" />,
                        contactLink: (
                          /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                          <a
                            href="mailto:support@socialie.com"
                            className="text-brandPrimary hover:underline"
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Route
        path={routes.settings.domains.new}
        render={({ history }) => (
          <SettingsDomainsAddModal
            onRequestClose={() => history.push(routes.settings.domains.root)}
          />
        )}
      />

      <Route
        path={routes.settings.domains.dns}
        render={({ match, history }) => {
          const id = match.params.id;
          const domain = id && domains?.find((d) => d.id === parseInt(id, 10));
          if (!domain) return null;
          return (
            <SettingsDomainsDNSModal
              domain={domain}
              onRequestClose={() => history.push(routes.settings.domains.root)}
            />
          );
        }}
      />

      <Route
        path={routes.settings.domains.ssl}
        render={({ match, history }) => {
          const id = match.params.id;
          const domain = id && domains?.find((d) => d.id === parseInt(id, 10));
          if (!domain) return null;
          return (
            <SettingsDomainsSSLModal
              domain={domain}
              onRequestClose={() => history.push(routes.settings.domains.root)}
            />
          );
        }}
      />
    </>
  );
}
