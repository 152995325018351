import { useHistory } from 'react-router-dom';
import { useCreateFanRequestMutation } from 'api/fanRequests';
import { useTranslation } from 'react-i18next';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import useFlashMessages from './useFlashMessages';

export default function useCreateFanRequest() {
  const history = useHistory();
  const { addFlashMessage } = useFlashMessages();
  const { t } = useTranslation();
  const [createFanRequest, { isLoading }] = useCreateFanRequestMutation();

  const handleCreate = async () => {
    const result = await createFanRequest({});

    if ('data' in result) {
      if ('errors' in result.data) {
        addFlashMessage(t('fanRequestError.500'), { isError: true });
      } else {
        // Navigate to the build request page with the new fan request ID
        history.push(routeFor(routes.requests.build, 'draft', result.data.id));
      }
    } else {
      addFlashMessage(t('errorPage.heading'), { isError: true });
    }
  };

  return { handleCreate, isLoading };
}
