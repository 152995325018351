import * as Sentry from '@sentry/react';

if (import.meta.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://0f77fa69b9bf9cbd200bed30db8ee23b@sentry.lon.bitshelter.com/18',

    environment:
      import.meta.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    release: import.meta.env.REACT_APP_SENTRY_RELEASE || undefined,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions

    // Session Replay
    replaysSessionSampleRate: Number(
      import.meta.env.REACT_APP_SENTRY_SESSION_REPLAY_SAMPLE_RATE || 0
    ),
    replaysOnErrorSampleRate: 1.0,
  });
}
