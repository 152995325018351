import { useUpdateFanAccountMutation } from 'api/fanAccount';
import camelize from 'camelize';
import Form from 'components/Form';
import useFlashMessages from 'hooks/useFlashMessages';
import usePlan from 'hooks/usePlan';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { IconCornerRounded, IconCornerSquare } from 'icons';
import omit from 'lodash/omit';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import FanAccount from 'types/FanAccount';
import FanAccountParams from 'types/FanAccountParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import ugcAvatarIcon from 'images/ugc-avatar-icon.png';
import { SettingsBrandPreview, SettingsBrandTopBar } from './components';

export default function SettingsBrand() {
  const fanAccount = useAppSelector(selectCurrentUser)?.currentFanAccount;
  const plan = usePlan();
  const { addFlashMessage } = useFlashMessages();
  const translateErrors = useTranslateErrors();
  const { t } = useTranslation();
  const [logoUrl, setLogoUrl] = useState(fanAccount?.logoUrl);
  const [sponsorLogoUrl, setSponsorLogoUrl] = useState(
    fanAccount?.sponsorLogoUrl
  );
  const [values, setValues] = useState<FanAccountParams>(
    omit(fanAccount!, 'id')
  );
  const [errors, setErrors] =
    useState<null | ValidationErrorPayload<FanAccount>>(null);

  const [updateFanAccount, { isLoading }] = useUpdateFanAccountMutation();
  const updateValues = (changes: Partial<FanAccountParams>) => {
    setValues((values) => ({ ...values, ...changes }));
  };
  if (!fanAccount) return null;

  const handleSave = async () => {
    setErrors(null);
    const result = await updateFanAccount(values);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        window.scroll({ top: 0, behavior: 'smooth' });
        setErrors(camelize(result.error.data.errors));
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
          timeout: false,
        });
      }
    } else {
      addFlashMessage(t('settings.brand.success'));
    }
  };

  return (
    <Form onSubmit={handleSave}>
      <SettingsBrandTopBar isLoading={isLoading} />

      <div className="flex items-stretch bg-white min-h-screen">
        <div className="flex-1">
          <div className="w-full max-w-54 mx-auto py-5 px-3">
            {!!errors && (
              <div className="notice notice--error">
                {t('settings.brand.errorNotice')}
              </div>
            )}

            <Form.Section
              title={t('settings.brand.company.title')}
              description={t('settings.brand.company.description')}
            >
              <Form.TextInput
                label={t('settings.brand.name.label')}
                value={values.name}
                errors={translateErrors(errors?.name)}
                onChange={(e) => updateValues({ name: e.target.value })}
              />

              <Form.Field label={t('settings.brand.logo.label')}>
                <Form.FileInput
                  aspectRatio={1}
                  extensions={['jpg', 'jpeg', 'png']}
                  thumbnailSize="small"
                  borderRadius={9999}
                  attachment={{
                    src: fanAccount.logoUrl,
                    contentType: fanAccount.logoContentType,
                  }}
                  placeholderIcon={ImagePlaceholder}
                  onChange={(logo) => updateValues({ logo })}
                  onSelectFile={(url) => setLogoUrl(url || undefined)}
                />
              </Form.Field>
            </Form.Section>
            <Form.Section
              title={t('settings.brand.mainPageColors.heading')}
              description={
                <Trans
                  i18nKey="settings.brand.mainPageColors.description"
                  values={{ url: fanAccount.fanAppUrl }}
                  components={{
                    myLink: (
                      /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                      <a
                        href={fanAccount.fanAppUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="text-brightBlue hover:underline"
                      />
                    ),
                  }}
                />
              }
            >
              <Form.ColorPicker
                label={t('settings.brand.backgroundColorHome.label')}
                description={t(
                  'settings.brand.backgroundColorHome.description'
                )}
                value={values.backgroundColorHome}
                onChange={(value) =>
                  updateValues({ backgroundColorHome: value })
                }
              />

              <Form.ColorPicker
                label={t('settings.brand.headlineTextColorHome.label')}
                description={t(
                  'settings.brand.headlineTextColorHome.description'
                )}
                value={values.headlineTextColorHome}
                onChange={(value) =>
                  updateValues({ headlineTextColorHome: value })
                }
              />

              <Form.ColorPicker
                label={t('settings.brand.bodyTextColorHome.label')}
                description={t('settings.brand.bodyTextColorHome.description')}
                value={values.bodyTextColorHome}
                onChange={(value) => updateValues({ bodyTextColorHome: value })}
              />
            </Form.Section>

            <Form.Section
              title={t('settings.brand.innerPageColors.heading')}
              description={t('settings.brand.innerPageColors.description')}
            >
              <Form.ColorPicker
                label={t('settings.brand.headlineTextColor.label')}
                description={t('settings.brand.headlineTextColor.description')}
                value={values.headlineTextColor}
                onChange={(value) => updateValues({ headlineTextColor: value })}
              />

              <Form.ColorPicker
                label={t('settings.brand.bodyTextColor.label')}
                description={t('settings.brand.bodyTextColor.description')}
                value={values.bodyTextColor}
                onChange={(value) => updateValues({ bodyTextColor: value })}
              />

              <Form.ColorPicker
                label={t('settings.brand.buttonBackgroundColor.label')}
                value={values.buttonBackgroundColor}
                onChange={(value) =>
                  updateValues({ buttonBackgroundColor: value })
                }
              />

              <Form.ColorPicker
                label={t('settings.brand.buttonTextColor.label')}
                value={values.buttonTextColor}
                onChange={(value) => updateValues({ buttonTextColor: value })}
              />
            </Form.Section>

            <Form.Section
              title={t('settings.brand.corners.heading')}
              description={t('settings.brand.corners.description')}
            >
              {(
                [
                  'buttonBorderRadius',
                  'cardBorderRadius',
                ] as (keyof FanAccountParams)[]
              ).map((field) => (
                <Form.Field
                  key={field}
                  label={t(`settings.brand.${field}.heading`)}
                >
                  <div className="flex items-start space-x-0.5 text-light">
                    <IconCornerSquare className="block w-3 h-3 bump-down-2" />
                    <div className="flex-1 bump-up-2 text-center">
                      <input
                        type="range"
                        step={2}
                        max={24}
                        className="range-input"
                        value={parseInt(values[field] as string, 10)}
                        onChange={(e) =>
                          updateValues({ [field]: `${e.target.value}px` })
                        }
                      />

                      <span className="text-dark text-12">{values[field]}</span>
                    </div>
                    <IconCornerRounded className="block w-3 h-3 bump-down-2" />
                  </div>
                </Form.Field>
              ))}
            </Form.Section>

            {!!plan.sponsor && (
              <Form.Section title={t('settings.brand.sponsor.heading')}>
                <Form.TextInput
                  label={t('settings.brand.sponsorName.label')}
                  value={values.sponsorName}
                  errors={translateErrors(errors?.sponsorName)}
                  onChange={(e) =>
                    updateValues({ sponsorName: e.target.value })
                  }
                />

                <Form.Field label={t('settings.brand.sponsorLogo.label')}>
                  <Form.FileInput
                    aspectRatio={1}
                    extensions={['jpg', 'jpeg', 'png']}
                    thumbnailSize="small"
                    borderRadius={9999}
                    attachment={{
                      src: fanAccount.sponsorLogoUrl,
                      contentType: fanAccount.sponsorLogoContentType,
                    }}
                    placeholderIcon={ImagePlaceholder}
                    onChange={(sponsorLogo) => updateValues({ sponsorLogo })}
                    onSelectFile={(url) => setSponsorLogoUrl(url || undefined)}
                  />
                </Form.Field>
              </Form.Section>
            )}
          </div>
        </div>

        <div className="flex-1 bg-grey9 py-5">
          <SettingsBrandPreview
            values={values}
            logoUrl={logoUrl}
            sponsorLogoUrl={sponsorLogoUrl}
          />
        </div>
      </div>
    </Form>
  );
}

const ImagePlaceholder = () => {
  const { t } = useTranslation();
  return <img src={ugcAvatarIcon} alt={t('photoshelterUGC')} />;
};
