import { useHistory } from 'react-router-dom';
import { useSwitchFanAccountMutation } from 'api/fanAccount';
import Tooltip from 'components/Tooltip';
import Avatar from 'components/Avatar';
import hasClass from 'helpers/hasClass';
import routeFor from 'helpers/routeFor';
import routes from 'helpers/routes';
import useClickOutside from 'hooks/useClickOutside';
import { IconDropdown, IconSearch } from 'icons';
import { lowerCase } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import ugcAvatarIcon from 'images/ugc-avatar-icon.png';

interface Props {
  currentUser: User;
  showDropDownIcon: boolean;
}

function FanAccountsMenu(props: Props) {
  const { currentUser, showDropDownIcon = true } = props;
  const history = useHistory();

  const brandIconButtonRef = useRef<HTMLButtonElement>(null);
  const dropDownButtonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const currentFanAccount = currentUser.currentFanAccount;

  const accounts = currentUser.fanAccounts;

  const [filter, setFilter] = useState('');

  const [switchFanAccount] = useSwitchFanAccountMutation();
  const handleSwitchAccount = async (id: number) => {
    await switchFanAccount({ id });
    window.location.href = routeFor(routes.requests.index, 'live');
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () =>
    accounts.length > 1
      ? setIsOpen((isOpen) => !isOpen)
      : history.push(routeFor(routes.requests.index, 'live'));

  const clickOut = useCallback(
    (e: any) => {
      if (!isOpen || hasClass(e.target, 'tippy-box')) return;
      if (brandIconButtonRef.current === e.target) return;
      if (brandIconButtonRef.current?.contains(e.target)) return;
      if (dropDownButtonRef.current === e.target) return;
      if (dropDownButtonRef.current?.contains(e.target)) return;
      setIsOpen(false);
    },
    [isOpen]
  );

  useClickOutside(wrapperRef, clickOut);

  return (
    <Tooltip
      placement="bottom-start"
      visible={isOpen}
      theme="AccountsMenu"
      interactive
      offset={[0, 16]}
      duration={[0, 0]}
      delay={[0, 0]}
      content={
        <div ref={wrapperRef}>
          {accounts.length > 9 && (
            <div className="mb-2 flex items-center relative hover:bg-transparent">
              <div className="absolute top-0 left-0 bottom-0 px-1 flex items-center">
                <IconSearch className="w-2.5 h-2.5 text-grey6" />
              </div>
              <input
                value={filter}
                className="mb-px w-full h-4 pl-4 text-14 text-dark placeholder:grey5 border border-solid border-brightBlue ring-4 ring-blueBg rounded-lg focus-visible:outline-none"
                onChange={(e) => setFilter(e.target.value)}
                placeholder={t('globals.placeholder.search')}
                type="text"
              />
            </div>
          )}

          <div className="w-full mb-2 py-0.75 border-0 border-b border-solid border-grey7 flyout-menu-item-disabled hover:bg-transparent font-bold">
            <p className="text-sm text-grey5 uppercase">
              {t('topbar.accountsMenu.chooseAccount')}
            </p>
          </div>
          <div>
            {accounts
              .filter(
                (a) =>
                  lowerCase(a.name).includes(lowerCase(filter)) || filter === ''
              )
              .map((account) => (
                <div
                  key={account.id}
                  className="p-1 flex items-center space-x-0.5 rounded-lg hover:bg-grey8 cursor-pointer"
                  onClick={() => handleSwitchAccount(account.id)}
                >
                  <div className="w-3 h-3 rounded-full border border-solid border-grey7 bg-grey8">
                    {account.logoUrl ? (
                      <img
                        src={account.logoUrl}
                        alt={`${account.name} logo`}
                        className="w-full h-full rounded-full"
                      />
                    ) : (
                      <div className="p-0.5">
                        <img src={ugcAvatarIcon} alt={t('photoshelterUGC')} />
                      </div>
                    )}
                  </div>
                  <p className="text-16 text-dark font-bold">{account.name}</p>
                </div>
              ))}
          </div>
        </div>
      }
    >
      <div className="flex items-center">
        <button
          onClick={toggleOpen}
          ref={brandIconButtonRef}
          className="w-4 h-4"
        >
          {currentFanAccount?.logoUrl ? (
            <Avatar
              src={currentFanAccount.logoUrl}
              name={currentFanAccount.name}
            />
          ) : (
            <img
              src={ugcAvatarIcon}
              alt={t('photoshelterUGC')}
              className="w-full h-full"
            />
          )}
        </button>

        {showDropDownIcon && accounts.length > 1 && (
          <div className="px-0.5">
            <button
              className="w-2 h-2 flex items-center justify-center"
              ref={dropDownButtonRef}
              onClick={toggleOpen}
            >
              <IconDropdown className="text-grey5" />
            </button>
          </div>
        )}
      </div>
    </Tooltip>
  );
}

export default FanAccountsMenu;
