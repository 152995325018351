import { useLogOutMutation } from 'api/auth';
import { Trans } from 'react-i18next';
import Invitation from 'types/Invitation';
import User from 'types/User';

interface Props {
  invitation: Invitation;
  currentUser: User;
}

export default function InvitationWrongUser(props: Props) {
  const { currentUser, invitation } = props;

  const [logOut] = useLogOutMutation();
  const handleLogOut = async () => {
    await logOut();
    window.location.reload();
  };

  return (
    <>
      <p className="mb-2">
        <Trans
          i18nKey="invitation.wrongUser"
          values={{
            recipientEmail: invitation.user.email,
            yourEmail: currentUser.email,
          }}
          components={{ bold: <strong /> }}
        />
      </p>
      <p>
        <Trans
          i18nKey="invitation.wrongUserLogOut"
          components={{
            btn: (
              <button
                className="text-brandPrimary hover:underline"
                onClick={handleLogOut}
              />
            ),
          }}
        />
      </p>
    </>
  );
}
