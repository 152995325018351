import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

export interface TooltipProps extends TippyProps {
  theme?:
    | 'dark'
    | 'primary'
    | 'primaryNoPad'
    | 'primaryDark'
    | 'FlyoutMenu'
    | 'AccountsMenu'
    | 'none';
}

const Tooltip = ({ children, ...tooltipProps }: TooltipProps) => {
  return (
    <Tippy
      theme="primary"
      duration={[150, 150]}
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [],
            },
          },
        ],
      }}
      arrow={false}
      appendTo={() => document.body}
      {...tooltipProps}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
