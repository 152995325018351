import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'types/User';
import Modal from 'components/Modal';
import Form from 'components/Form';
import useUpdateProfile from 'hooks/useUpdateProfile';
import useFlashMessages from 'hooks/useFlashMessages';
import SettingsProfileSection from './SettingsProfileSection';

interface Props {
  currentUser: User;
}

export default function SettingsProfileName(props: Props) {
  const { currentUser } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState({
    firstName: currentUser.firstName || '',
    lastName: currentUser.lastName || '',
  });
  const { addFlashMessage } = useFlashMessages();

  const [updateProfile, { isLoading }] = useUpdateProfile({
    onSuccess: () => {
      addFlashMessage(t('settings.profile.name.success'));
      setIsOpen(false);
    },
  });

  const handleCancel = () => {
    setValues({
      firstName: currentUser.firstName || '',
      lastName: currentUser.lastName || '',
    });
    setIsOpen(false);
  };

  const handleSubmit = () => {
    updateProfile(values);
  };

  return (
    <>
      <SettingsProfileSection
        label={t('settings.profile.name.label')}
        onClick={() => setIsOpen(true)}
      >
        <div className="text-dark text-14">{currentUser.name}</div>
      </SettingsProfileSection>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        renderHeading={t('settings.profile.name.modal.heading')}
        renderActions={
          <>
            <button className="btn btn--grey" onClick={handleCancel}>
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--primary"
              onClick={handleSubmit}
              disabled={isLoading}
              data-loading={isLoading}
            >
              {t('global.save')}
            </button>
          </>
        }
      >
        <Form onSubmit={handleSubmit}>
          <Form.TextInput
            label={t('settings.profile.name.modal.firstName.label')}
            value={values.firstName}
            onChange={(e) =>
              setValues((values) => ({ ...values, firstName: e.target.value }))
            }
          />

          <Form.TextInput
            label={t('settings.profile.name.modal.lastName.label')}
            value={values.lastName}
            onChange={(e) =>
              setValues((values) => ({ ...values, lastName: e.target.value }))
            }
          />
        </Form>
      </Modal>
    </>
  );
}
