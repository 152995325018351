import { IconCopy } from 'icons';
import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from './Tooltip';

interface Props {
  value: string;
  className?: string;
}

export default function CopyButton(props: Props) {
  const { value } = props;
  const { t } = useTranslation();
  const [didCopy, setDidCopy] = useState(false);

  const handleCopy = () => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = value;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    setDidCopy(true);
    setTimeout(() => setDidCopy(false), 750);
  };

  return (
    <Tooltip
      content={t('copyButton.copied')}
      visible={didCopy}
      arrow
      theme="primaryDark"
    >
      <button
        className={cx('w-full h-full', props.className)}
        onClick={handleCopy}
      >
        <IconCopy className="block w-full h-full" />
      </button>
    </Tooltip>
  );
}
