import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function RequestLimitModal(props: Props) {
  const { isOpen, onRequestClose } = props;
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      theme="notification"
      renderHeading={t('fanRequestBuilder.requestLimitModal.heading')}
      renderActions={
        <button
          className="btn btn--primary btn--small"
          onClick={onRequestClose}
        >
          {t('global.close')}
        </button>
      }
    >
      {t('fanRequestBuilder.requestLimitModal.description')}
    </Modal>
  );
}
