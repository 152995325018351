import { useUpdateFanRequestMutation } from 'api/fanRequests';
import camelize from 'camelize';
import FanRequestPreview from 'components/FanRequestPreview';
import ProtectUnsavedChanges from 'components/ProtectUnsavedChanges';
import RequestLimitModal from 'components/RequestLimitModal';
import useFlashMessages from 'hooks/useFlashMessages';
import useStateWithPromise from 'hooks/useStateWithPromise';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import FanRequest from 'types/FanRequest';
import FanRequestParams from 'types/FanRequestParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import { FanRequestFormFields, FanRequestFormTopBar } from './components';

interface Props {
  fanRequest: FanRequest;
  context?: 'builder' | 'edit';
}

export interface FanRequestFormSaveOptions {
  onSuccess?: () => void;
  onError?: () => void;
  values?: Partial<FanRequestParams>;
  trigger?:
    | 'save-now'
    | 'publish'
    | 'save+close'
    | 'save-draft'
    | 'save+preview'
    | 'any'
    | null;
}

export default function FanRequestForm(props: Props) {
  const { fanRequest, context = 'builder' } = props;

  // Track which button triggered a save
  const [saveTriggeredBy, setSaveTriggeredBy] =
    useState<FanRequestFormSaveOptions['trigger']>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useStateWithPromise(false);

  const [values, setValues] = useState(buildDefaultValues(fanRequest));

  const [dataUrls, setDataUrls] = useState({
    coverPhoto: fanRequest.coverPhotoUrl as string | null,
    featuredContent: fanRequest.featuredContentUrl as string | null,
  });

  const currentFanAccount =
    useAppSelector(selectCurrentUser)?.currentFanAccount;

  const { t } = useTranslation();

  const [updateFanRequest] = useUpdateFanRequestMutation();

  const [errors, setErrors] =
    useState<null | ValidationErrorPayload<FanRequest>>(null);

  const [isRequestLimitModalOpen, setIsRequestLimitModalOpen] = useState(false);

  const [activeUploadCount, setActiveUploadCount] = useState(0);

  const { addFlashMessage } = useFlashMessages();

  const handleChange = async (changes: Partial<FanRequestParams>) => {
    setHasUnsavedChanges(true);
    setValues((values) => ({ ...values, ...changes }));
  };

  const handleChangeDataUrl = (changes: Partial<typeof dataUrls>) => {
    setDataUrls((dataUrls) => ({ ...dataUrls, ...changes }));
  };

  const handleSave = async (options: FanRequestFormSaveOptions = {}) => {
    setErrors(null);
    setSaveTriggeredBy(options.trigger || 'any');
    const nextValues = { ...values, ...options.values };
    setValues(nextValues);
    const result = await updateFanRequest({
      id: fanRequest.id,
      values: nextValues,
    });
    setSaveTriggeredBy(null);

    if ('error' in result) {
      if ('data' in result.error && result.error.status === 422) {
        if (
          result.error.data.errors.base?.[0] ===
          'validation.requestLimitReached'
        ) {
          setIsRequestLimitModalOpen(true);
        } else {
          window.scroll({ top: 0, behavior: 'smooth' });
          setErrors(camelize(result.error.data.errors));
        }
      } else {
        addFlashMessage(t('global.unexpectedError'), {
          isError: true,
          timeout: false,
        });
      }
      options.onError?.();
    } else {
      addFlashMessage(t('fanRequestBuilder.messages.success'));
      setHasUnsavedChanges(false);
      options.onSuccess?.();
    }
  };

  if (!currentFanAccount) return null;

  return (
    <div>
      <Helmet title={t(`fanRequestBuilder.pageTitles.${context}`)} />
      <FanRequestFormTopBar
        fanRequest={fanRequest}
        values={values}
        context={context}
        onSave={handleSave}
        saveTriggeredBy={saveTriggeredBy}
        disableUnsavedChangesPrompt={() => setHasUnsavedChanges(false)}
        hasUnsavedChanges={hasUnsavedChanges}
        activeUploadCount={activeUploadCount}
      />

      <div className="flex items-stretch bg-white min-h-screen">
        <div className="flex-1 py-5">
          <FanRequestFormFields
            values={values}
            onChange={handleChange}
            onChangeDataUrl={handleChangeDataUrl}
            fanRequest={fanRequest}
            errors={errors}
            currentFanAccount={currentFanAccount}
            onUploadStart={() => setActiveUploadCount((i) => i + 1)}
            onUploadStop={() => setActiveUploadCount((i) => i - 1)}
          />
        </div>

        <div className="flex-1 bg-grey9 py-5">
          <FanRequestPreview
            values={values}
            dataUrls={dataUrls}
            fanAccount={currentFanAccount}
          />
        </div>
      </div>

      {hasUnsavedChanges && (
        <ProtectUnsavedChanges msg={t('fanRequestBuilder.form.prompt')} />
      )}

      <RequestLimitModal
        isOpen={isRequestLimitModalOpen}
        onRequestClose={() => setIsRequestLimitModalOpen(false)}
      />
    </div>
  );
}

// Merge the default values with any non-null values from the fanRequest
function buildDefaultValues(fanRequest: FanRequest): FanRequestParams {
  const defaultValues: FanRequestParams = {
    status: 'draft',
    urlSlug: '',
    subject: '',
    instructions: '',
    talkingPointsTitle: '',
    talkingPoints: [''],
    mediaType: '',
    orientation: '',
    introHeader: '',
    introDescription: '',
    introButtonText: '',
    featuredContentTitle: '',
    featuredContentDescription: '',
    backgroundColor: '',
    backgroundBlurAmount: '0px',
    backgroundOverlayOpacity: 0.0,
    videoDuration: null,
    expiresAt: null,
    dataCapture: null,
  };

  return {
    ...defaultValues,
    ...(Object.keys(defaultValues) as (keyof FanRequest)[])
      .filter((k) => fanRequest[k] != null)
      .reduce((a, k) => ({ ...a, [k]: fanRequest[k] }), {}),
  };
}
