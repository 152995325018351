import React from 'react';
import { IconLoader } from 'icons';
import cn from 'classnames';

interface Props {
  delay?: number;
  theme?: 'mono';
}

interface State {
  showLoader: boolean;
}

class Loader extends React.Component<Props, State> {
  private timeout?: number = undefined;

  state: State = { showLoader: false };

  componentDidMount() {
    const delay =
      !this.props.delay && this.props.delay !== 0 ? 750 : this.props.delay;

    this.timeout = window.setTimeout(
      () => this.setState({ showLoader: true }),
      delay
    );
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  render() {
    const classNames = cn(
      'p-1',
      'w-full',
      'text-center',
      'opacity-0',
      'text-brandPrimary',
      {
        'opacity-100': this.state.showLoader,
        'text-grey5': this.props.theme === 'mono',
      }
    );
    return (
      <div className={classNames} data-testid="loader-spinner">
        <IconLoader className="w-6 h-6 inline" />
      </div>
    );
  }
}

export default Loader;
