import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import FanRequest from 'types/FanRequest';
import { useDeleteFanRequestMutation } from 'api/fanRequests';
import useFlashMessages from 'hooks/useFlashMessages';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  fanRequest: FanRequest;
  onSuccess?: () => void;
}

export default function FanRequestDeleteModal(props: Props) {
  const { isOpen, onRequestClose, onSuccess, fanRequest } = props;
  const [deleteFanRequest, { isLoading }] = useDeleteFanRequestMutation();
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();

  const handleDelete = async () => {
    const result = await deleteFanRequest({ id: fanRequest.id });
    if ('error' in result) {
      addFlashMessage(t('global.unexpectedError'), { isError: true });
    } else {
      addFlashMessage(t('fanRequestDeleteModal.success'));
      onRequestClose();
      onSuccess?.();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('fanRequestDeleteModal.heading')}
      renderActions={
        <>
          <button className="btn btn--grey" onClick={onRequestClose}>
            {t('global.cancel')}
          </button>

          <button
            className="btn btn--primary"
            disabled={isLoading}
            data-loading={isLoading}
            onClick={handleDelete}
          >
            {t('fanRequestDeleteModal.continue')}
          </button>
        </>
      }
    >
      {t('fanRequestDeleteModal.body')}
    </Modal>
  );
}
