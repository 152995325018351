import './sentry';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css';
import 'styles/index.css';
import 'styles/tippy-overrides.css';
import Layout from 'components/Layout';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import './i18n';
import { FlashMessagesProvider } from 'hooks/useFlashMessages';
import { SocketProvider } from 'hooks/useSocket';
import { HelmetProvider } from 'react-helmet-async';

(window as any).Chargebee.init({
  site: import.meta.env.REACT_APP_CHARGEBEE_SITE,
  publishableKey: import.meta.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <SocketProvider>
        <Provider store={store}>
          <FlashMessagesProvider>
            <Layout />
          </FlashMessagesProvider>
        </Provider>
      </SocketProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
