import { useFetchAccountUsersQuery } from 'api/fanAccountUser';
import Form from 'components/Form';
import Loader from 'components/Loader';
import { IconPlus, IconTelescope } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import {
  SettingsUsersInviteUserModal,
  SettingsUsersPermissionsTooltip,
  SettingsUsersRow,
  SettingsUsersTopBar,
} from './components';

function SettingsUsers() {
  const { t } = useTranslation();
  const [userStatus, setUserStatus] = useState<'active' | 'pending'>('active');
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const currentUser = useAppSelector(selectCurrentUser);

  const {
    isLoading,
    error,
    data: fanAccountUsers,
  } = useFetchAccountUsersQuery();

  if (isLoading) return <Loader />;

  if (error) return <div>There was an error: {JSON.stringify(error)}</div>;

  if (!fanAccountUsers || !currentUser) return null;

  const activeUsers = fanAccountUsers.filter((f) => !f.pending);
  const pendingUsers = fanAccountUsers.filter((f) => f.pending);
  const usersToShow = userStatus === 'pending' ? pendingUsers : activeUsers;

  return (
    <>
      <div className="bg-white max-w-100 mx-auto">
        <div>
          <SettingsUsersTopBar />
        </div>
        <div className="px-2 py-6">
          <div className="flex items-start mb-4">
            <Form.ToggleGroup>
              <Form.ToggleGroup.Option
                isSelected={userStatus === 'active'}
                onSelect={() => setUserStatus('active')}
                title={t('settings.users.userStatus.active', {
                  count: activeUsers.length,
                })}
              />

              <Form.ToggleGroup.Option
                isSelected={userStatus === 'pending'}
                onSelect={() => setUserStatus('pending')}
                title={t('settings.users.userStatus.pending', {
                  count: pendingUsers.length,
                })}
              />
            </Form.ToggleGroup>

            {currentUser?.role === 'owner' && (
              <div className="ml-auto">
                <button
                  className="btn btn--grey btn--small"
                  onClick={() => setIsInviteModalOpen(true)}
                >
                  <span className="flex items-center">
                    <IconPlus />
                    <span>{t('settings.users.invite.button')}</span>
                  </span>
                </button>
              </div>
            )}
          </div>
          {!!usersToShow.length ? (
            <div className="flex flex-col">
              <div className="flex">
                <div className="flex-grow">
                  <span className="text-14 text-grey5 leading-20 font-bold">
                    {t('settings.users.table.name')}
                  </span>
                </div>
                {currentUser?.role === 'owner' && (
                  <div className="flex-shrink-0">
                    <div className="w-21">
                      <span className="flex items-center text-14 text-grey5 leading-20 font-bold">
                        {t('settings.users.table.permissions')}
                        <div className="ml-0.5 bump-down-1">
                          <SettingsUsersPermissionsTooltip />
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {usersToShow.map((fanAccountUser) => (
                  <SettingsUsersRow
                    key={fanAccountUser.id}
                    currentUser={currentUser}
                    fanAccountUser={fanAccountUser}
                  />
                ))}
              </div>
            </div>
          ) : userStatus === 'pending' ? (
            <div className="flex flex-col items-center pt-8">
              <IconTelescope className="mb-1 text-dark w-5.5 h-5.5" />

              <div className="mb-2 text-dark font-bold text-20">
                {t('settings.users.invite.empty')}
              </div>

              {currentUser?.role === 'owner' && (
                <button
                  className="btn btn--grey btn--inlineBlock btn--small"
                  onClick={() => setIsInviteModalOpen(true)}
                >
                  <span className="flex items-center">
                    <IconPlus />
                    <span>{t('settings.users.invite.button')}</span>
                  </span>
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <SettingsUsersInviteUserModal
        isOpen={isInviteModalOpen}
        onRequestClose={() => setIsInviteModalOpen(false)}
      />
    </>
  );
}

export default SettingsUsers;
