import { IconUGCLogo } from 'icons';
import {
  SignUpPaymentFAQ,
  SignUpPaymentForm,
  SignUpPaymentPlan,
  SignUpPaymentUserMenu,
} from './components';
import Estimate from 'types/Estimate';

interface Props {
  estimate: Estimate;
  company: string;
}

export default function SignUpPayment(props: Props) {
  const { estimate, company } = props;

  return (
    <div className="min-h-screen overflow-x-hidden relative bg-grey9">
      <div className="absolute top-0 left-0 px-1 tablet:px-2 py-1 tablet:py-3 z-50">
        <div className="p-1 rounded">
          <IconUGCLogo className="text-dark w-full h-full" />
        </div>
      </div>

      <SignUpPaymentUserMenu />

      <div className="tablet:mb-2 desktop:mb-5 hidden tablet:block absolute top-40 pointer-events-none tablet:-left-58 desktop:-left-51">
        <div className="w-72 h-72 bg-transparent border-12 border-solid border-brandPrimary rounded-full" />
      </div>
      <div className="mt-14 hidden tablet:block absolute top-0 tablet:-right-36 desktop:-right-34">
        <div className="w-45 h-45 bg-socialieBlue rounded-full" />
      </div>

      <div className="mx-auto mt-12 w-105 relative z-10">
        <div className="flex items-start px-4 space-x-3 mb-10">
          <div className="flex-1">
            <SignUpPaymentForm estimate={estimate} company={company} />
          </div>

          <div className="w-32">
            <SignUpPaymentPlan estimate={estimate} />
          </div>
        </div>

        <SignUpPaymentFAQ />
      </div>
    </div>
  );
}
