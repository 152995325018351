import { useCreateCardMutation } from 'api/cards';
import Modal from 'components/Modal';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Subscription from 'types/Subscription';
import SettingsSubscriptionSection from './SettingsSubscriptionSection';
import { CardComponent } from '@chargebee/chargebee-js-react-wrapper';
import Form from 'components/Form';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import SubscriptionParams from 'types/SubscriptionParams';
import useTokenizeCard from 'hooks/useTokenizeCard';
import useTranslateErrors from 'hooks/useTranslateErrors';
import camelize from 'camelize';
import useFlashMessages from 'hooks/useFlashMessages';

interface Props {
  subscription: Subscription;
}

type Values = Pick<SubscriptionParams, 'zip'>;

interface Errors extends ValidationErrorPayload<Values> {
  card?: string[] | null;
}

export default function SettingsSubscriptionBilling(props: Props) {
  const cardRef = useRef<any>(null);
  const [tokenizeCard, { isTokenizing }] = useTokenizeCard(cardRef);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState<Values>({
    zip: '',
  });
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [errors, setErrors] = useState<Errors | null>(null);
  const { subscription } = props;
  const { t } = useTranslation();
  const [createCard, { isLoading }] = useCreateCardMutation();
  const translateErrors = useTranslateErrors();
  const { addFlashMessage } = useFlashMessages();

  const handleSubmit = async () => {
    if (!cardRef.current) return;

    tokenizeCard({
      billingZip: values.zip,
      onSuccess: async (token) => {
        const result = await createCard({ ...values, token });
        if ('error' in result) {
          if ('data' in result.error && result.error.status === 422) {
            setErrors(camelize(result.error.data.errors));
          } else {
            addFlashMessage(t('global.unexpectedError'), { isError: true });
          }
        } else {
          setValues({ zip: '' });
          cardRef.current.clear();
          addFlashMessage(t('settings.subscription.billing.success'));
          setIsModalOpen(false);
        }
      },
    });
  };

  const handleChangeCard = (status: {
    complete: boolean;
    error?: { message: string };
  }) => {
    setIsCardComplete(status.complete);
    setErrors((errors) =>
      errors == null && !status.error
        ? null
        : {
            ...errors,
            card: status.error ? [status.error.message] : null,
          }
    );
  };

  return (
    <SettingsSubscriptionSection
      heading={t('settings.subscription.billing.heading')}
    >
      <div className="text-dark text-14 mb-1.5">
        {t(
          `settings.subscription.billing.cardType.${subscription.card.cardType}`
        )}{' '}
        {t('settings.subscription.billing.last4', {
          last4: subscription.card.last4,
        })}
      </div>

      <button
        className="btn btn--grey btn--inlineBlock btn--small"
        onClick={() => setIsModalOpen(true)}
      >
        {t('settings.subscription.billing.changeButton')}
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        renderHeading={t('settings.subscription.billing.updateModal.heading')}
        renderActions={
          <>
            <button
              className="btn btn--grey"
              onClick={() => setIsModalOpen(false)}
            >
              {t('global.cancel')}
            </button>

            <button
              className="btn btn--primary"
              onClick={handleSubmit}
              data-loading={isLoading || isTokenizing}
              disabled={
                isLoading ||
                isTokenizing ||
                !isCardComplete ||
                !values.zip ||
                !!errors?.card?.length
              }
            >
              {t('settings.subscription.billing.updateModal.confirmButton')}
            </button>
          </>
        }
      >
        <div className="text-dark mb-2">
          {t('settings.subscription.billing.updateModal.description')}
        </div>

        <Form.Field
          label={t(`onboarding.signUp.payment.card`)}
          errors={errors?.card}
        >
          <div>
            <CardComponent
              ref={cardRef}
              onChange={handleChangeCard}
              fonts={['https://use.typekit.net/gcd4qci.css']}
              styles={{
                base: {
                  fontFamily: 'Outfit',
                  fontSize: '16px',
                  color: '#101820',
                  '::placeholder': {
                    color: '#9198A1',
                  },
                },
              }}
              options={{
                hidePostalCode: true,
                style: {},
              }}
            />
          </div>
        </Form.Field>

        <Form.TextInput
          value={values.zip}
          name="zip"
          label={t(`onboarding.signUp.payment.zip`)}
          errors={translateErrors(errors?.zip)}
          onChange={(e) =>
            setValues((values) => ({ ...values, zip: e.target.value }))
          }
        />
      </Modal>
    </SettingsSubscriptionSection>
  );
}
