import { IconPlus } from 'icons';
import { useTranslation } from 'react-i18next';
import useCreateFanRequest from 'hooks/useCreateFanRequests';

interface Props {
  renderButton?: (onClick: () => void, isLoading: boolean) => React.ReactNode;
}

export default function NewRequestButton(props: Props) {
  const { renderButton } = props;
  const { t } = useTranslation();
  const { handleCreate, isLoading } = useCreateFanRequest();

  if (renderButton) return <>{renderButton(handleCreate, isLoading)}</>;

  return (
    <button
      onClick={handleCreate}
      data-loading={isLoading}
      disabled={isLoading}
      className="btn btn--outline btn--small mr-2 inline-block w-auto"
    >
      <span className="flex items-center">
        <IconPlus />
        <span className="mt-0.25">{t('globalMenu.userMenu.newRequest')}</span>
      </span>
    </button>
  );
}
