import React, { useState, useCallback, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { selectCurrentUser } from 'redux/reducers/auth';
import { useAppSelector } from 'redux/store';
import { useTranslation } from 'react-i18next';
import routes from 'helpers/routes';
import hasClass from 'helpers/hasClass';
import routeFor from 'helpers/routeFor';
import useClickOutside from 'hooks/useClickOutside';
import {
  IconBranding,
  IconGroups,
  IconSubscription,
  IconDomain,
  IconCaretLeft,
  IconCaretRight,
  IconRequest,
  IconShare,
  IconPlusV2,
  IconCaretDown,
  IconCaretUp,
} from 'icons';
import useCreateFanRequest from 'hooks/useCreateFanRequests';
import UserMenu from '../Layout/components/UserMenu';
import FanAccountsMenu from '../Layout/components/FanAccountsMenu';
import OverLimitWarning from '../Layout/components/OverLimitWarning';
import UpsellPromo from './components/UpsellPromo';

interface GlobalSidebarNavProps {
  isOpen: boolean;
  handleOnOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function GlobalSidebarNav(props: GlobalSidebarNavProps) {
  const { isOpen, handleOnOpen } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const [isUGCAccordionOpen, isSetUGCAccordionOpen] = useState(true);
  const { handleCreate, isLoading } = useCreateFanRequest();

  const sidebarWrapperRef = useRef<HTMLDivElement>(null);

  const toggleSidebar = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleOnOpen((prevIsOpen) => !prevIsOpen);
  };

  const clickOut = useCallback(
    (e: any) => {
      if (!isOpen || hasClass(e.target, 'tippy-box')) return;
      handleOnOpen(false);
    },
    [isOpen, handleOnOpen]
  );

  useClickOutside(sidebarWrapperRef, clickOut);

  const currentUser = useAppSelector(selectCurrentUser);

  if (!currentUser) return null;

  const linkProps = {
    className: `flex ${
      isOpen ? 'justify-start p-1 delay-50 w-full' : 'justify-center w-4'
    } p-0.25 py-0.5 rounded-lg text-grey5 text-14 hover:bg-grey3 hover:text-white mb-1 transition-width duration-300 ease-in-out`,
    activeClassName: 'bg-grey3 !text-white font-medium',
  };

  return (
    <>
      <div className="relative h-full">
        <div
          ref={sidebarWrapperRef}
          className={`global-sidebar group ${isOpen ? 'w-auto' : 'w-7'} `}
        >
          <nav className={`${isOpen ? 'grid-col-200px' : 'grid-col-52'} `}>
            <div
              className={`global-sidebar-nav-content self-start ${
                isOpen ? 'pr-0.5' : 'p-0'
              }`}
            >
              <div className="border-solid border-b-1 border-grey2">
                <div className="py-2 px-1.5 flex items-center">
                  <FanAccountsMenu
                    currentUser={currentUser}
                    showDropDownIcon={isOpen}
                  />
                  <button
                    className="global-sidebar-toggle-btn"
                    onClick={toggleSidebar}
                  >
                    {isOpen ? <IconCaretLeft /> : <IconCaretRight />}
                  </button>
                </div>
              </div>
              <div className="pt-2 pb-1">
                <div className="px-1.5">
                  <div
                    className={`text-white font-medium border-b-1 border-solid border-grey2 pb-1`}
                  >
                    {isOpen && (
                      <div className="flex">
                        <button
                          className="flex flex-1 items-center justify-between text-white pr-0.5"
                          onClick={(event) => {
                            event.stopPropagation();
                            isSetUGCAccordionOpen(!isUGCAccordionOpen);
                          }}
                        >
                          <span>{t('globalMenu.UGC')}</span>
                          <span className="text-grey5">
                            {isUGCAccordionOpen ? (
                              <IconCaretUp />
                            ) : (
                              <IconCaretDown />
                            )}
                          </span>
                        </button>
                      </div>
                    )}

                    {(isUGCAccordionOpen || !isOpen) && (
                      <div
                        className={`pt-1.25 grid ${
                          isOpen ? 'delay-50 w-full' : 'justify-center w-4'
                        } transition-width duration-300 ease`}
                      >
                        <button
                          onClick={handleCreate}
                          disabled={isLoading}
                          className={`${
                            location.pathname.includes('/requests/draft/')
                              ? 'bg-grey3 text-white font-medium'
                              : 'text-grey5'
                          } ${
                            isOpen
                              ? 'w-auto justify-start'
                              : 'w-4.5 justify-center'
                          } flex items-center p-0.5 h-4.5 rounded-lg text-14 hover:bg-grey3 hover:text-white mb-1`}
                        >
                          <IconPlusV2 className="w-3 h-3" />
                          <span
                            className={`${
                              isOpen
                                ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                                : 'opacity-0 w-0 p-0'
                            }`}
                          >
                            {t('globalMenu.createRequest')}
                          </span>
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="pt-2">
                    <NavLink
                      to={routeFor(routes.requests.index, 'live')}
                      {...linkProps}
                    >
                      <div className="flex items-center">
                        <IconRequest className="w-2.25 h-2.25 m-0.5" />
                        <span
                          className={`${
                            isOpen
                              ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                              : 'opacity-0 w-0 p-0'
                          }`}
                        >
                          {t('globalMenu.request')}
                        </span>
                      </div>
                    </NavLink>

                    <NavLink to={routes.settings.brand} {...linkProps}>
                      <div className="flex items-center">
                        <IconBranding className="w-2.25 h-2.25 m-0.5" />
                        <span
                          className={`${
                            isOpen
                              ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                              : 'opacity-0 w-0 p-0'
                          }`}
                        >
                          {t('settings.nav.brand')}
                        </span>
                      </div>
                    </NavLink>

                    <NavLink to={routes.settings.users} {...linkProps}>
                      <div className="flex items-center">
                        <IconGroups className="w-2.25 h-2.25 m-0.5" />
                        <span
                          className={`${
                            isOpen
                              ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                              : 'opacity-0 w-0 p-0'
                          }`}
                        >
                          {t('settings.nav.users')}
                        </span>
                      </div>
                    </NavLink>

                    <NavLink to={routes.settings.domains.root} {...linkProps}>
                      <div className="flex items-center">
                        <IconDomain className="w-2.25 h-2.25 m-0.5" />
                        <span
                          className={`${
                            isOpen
                              ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                              : 'opacity-0 w-0 p-0'
                          }`}
                        >
                          {t('settings.nav.domains')}
                        </span>
                      </div>
                    </NavLink>

                    <NavLink to={routes.settings.subscription} {...linkProps}>
                      <div className="flex items-center">
                        <IconSubscription className="w-2.25 h-2.25 m-0.5" />
                        <span
                          className={`${
                            isOpen
                              ? 'transition ease-in-out delay-150 duration-75 opacity-100 w-full pl-1'
                              : 'opacity-0 w-0 p-0'
                          }`}
                        >
                          {t('settings.nav.subscription')}
                        </span>
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              {isOpen && (
                <div className="py-2 px-2.5 border-t-1 border-solid border-grey2">
                  <UpsellPromo />
                  <a
                    href="https://go.photoshelter.com/brands/collaboration/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-between items-center h-4 rounded-lg text-white font-medium text-14  hover:text-grey5 mb-1 -ml-0.5"
                  >
                    <span className="pr-1">{t('globalMenu.distribution')}</span>
                    <IconShare className="text-grey5" />
                  </a>

                  <a
                    href="https://go.photoshelter.com/brands/digital-asset-management/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex justify-between items-center h-4 rounded-lg text-white font-medium text-14 hover:text-grey5 mb-1 -ml-0.5"
                  >
                    <span className="pr-1">
                      {t('globalMenu.assetManagement')}
                    </span>
                    <IconShare className="text-grey5" />
                  </a>
                </div>
              )}
            </div>

            <div className="self-end px-1 py-3 border-t-1 border-solid border-grey2">
              <div
                className={`flex items-center ${
                  isOpen ? ' justify-start ' : 'justify-center'
                }`}
              >
                <UserMenu currentUser={currentUser} />

                {isOpen && (
                  <div className="pl-1 text-12 text-white w-16 leading-16">
                    <p className="truncate">{currentUser?.name}</p>
                    <p className="text-grey5 capitalize">{currentUser?.role}</p>
                  </div>
                )}
              </div>
            </div>
          </nav>
          <div className="global-sidebar-border-hover-effect"></div>
        </div>
      </div>
      <div className="fixed right-0 bottom-1 z-50 bg-white rounded-2xl">
        <OverLimitWarning />
      </div>
    </>
  );
}

export default GlobalSidebarNav;
