import { useLogInMutation } from 'api/auth';
import Form from 'components/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Invitation from 'types/Invitation';

interface Props {
  invitation: Invitation;
}

export default function InvitationLogIn(props: Props) {
  const { invitation } = props;
  const { t } = useTranslation();
  const [logIn, { data, isLoading, error }] = useLogInMutation();
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    logIn({ contactValue: invitation.user.email!, password });
  };

  const hasError = !!(data && 'error' in data) || !!error;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">{t('invitation.logIn.description')}</div>

      <div className="mb-4">
        <Form.TextInput
          name="contactValue"
          value={invitation.user.email}
          disabled
          label={t('invitation.logIn.email')}
        />

        <Form.TextInput
          name="password"
          type="password"
          errors={
            hasError ? t('invitation.logIn.incorrectPassword') : undefined
          }
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label={t('auth.logIn.password.label')}
          placeholder={t('auth.logIn.password.placeholder')}
        />
      </div>

      <button
        className="btn btn--primary"
        type="submit"
        disabled={isLoading || !password}
        data-loading={isLoading}
      >
        {t('auth.logIn.submit')}
      </button>
    </Form>
  );
}
