import {
  useCheckForExistingUserMutation,
  useSignUpUserMutation,
} from 'api/user';
import camelize from 'camelize';
import Form from 'components/Form';
import isValidContactValue from 'helpers/isValidContactValue';
import useFlashMessages from 'hooks/useFlashMessages';
import useTranslateErrors from 'hooks/useTranslateErrors';
import { IconArrowRight } from 'icons';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SignUpUserParams from 'types/SignUpUserParams';
import ValidationErrorPayload from 'types/ValidationErrorPayload';
import SignUpExistingAccountWarning from './SignUpExistingAccountWarning';

interface Props {
  onChangeEmail: (email: string) => void;
  onChangeCompany: (company: string) => void;
  onSignIn: () => void;
}
export default function SignUpForm(props: Props) {
  const { onChangeEmail, onChangeCompany, onSignIn } = props;
  const { t } = useTranslation();
  const { addFlashMessage } = useFlashMessages();
  const [values, setValues] = useState<SignUpUserParams>({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    password: '',
    passwordConfirmation: '',
  });
  const [errors, setErrors] =
    useState<ValidationErrorPayload<SignUpUserParams> | null>(null);

  const translateErrors = useTranslateErrors();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const [hasExistingAccount, setHasExistingAccount] = useState(false);
  const [isAccountWarningDismissed, setIsAccountWarningDismissed] =
    useState(false);

  const [checkForExistingUser] = useCheckForExistingUserMutation();
  const [signUpUser, { isLoading }] = useSignUpUserMutation();

  const handleFindByEmail = async (email: string) => {
    onChangeEmail(email);
    setIsAccountWarningDismissed(false);

    if (!isValidContactValue(email)) {
      setHasExistingAccount(false);
      return;
    }

    const result = await checkForExistingUser({ email });
    const hasAccount = 'data' in result && result.data.success;
    setHasExistingAccount(hasAccount);
  };

  const handleSubmit = async () => {
    setErrors(null);
    if (isValidContactValue(values.email)) {
      const result = await signUpUser(values);

      // Happy path is managed by global state currentUser getting created
      if ('error' in result) {
        if ('data' in result.error && result.error.status === 422) {
          window.scroll({ top: 0, behavior: 'smooth' });
          setErrors(camelize(result.error.data.errors));
        } else {
          addFlashMessage(t('global.unexpectedError'), { isError: true });
        }
      }
    } else {
      setErrors((errors) => ({ email: ['global.invalidEmail'] }));
    }
  };

  return (
    <>
      <div className="mb-2">
        <h1 className="h2 tablet:h1">{t('onboarding.signUp.main.heading')}</h1>
      </div>
      <Form onSubmit={handleSubmit} noValidate>
        <div className="w-full">
          <div className="flex flex-1 mb-3 space-x-2">
            <div className="flex-1">
              <Form.TextInput
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                errors={translateErrors(errors?.firstName)}
                label={t('onboarding.form.firstName.label')}
                placeholder={t('onboarding.form.firstName.placeholder')}
              />
            </div>
            <div className="flex-1">
              <Form.TextInput
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                errors={translateErrors(errors?.lastName)}
                label={t('onboarding.form.lastName.label')}
                placeholder={t('onboarding.form.lastName.placeholder')}
              />
            </div>
          </div>

          <Form.TextInput
            name="companyName"
            value={values.companyName}
            onChange={(e) => {
              handleChange(e);
              onChangeCompany(e.target.value);
            }}
            errors={translateErrors(errors?.companyName)}
            label={t('onboarding.form.companyName.label')}
            placeholder={t('onboarding.form.companyName.placeholder')}
          />

          <div className="mb-3">
            <div>
              <Form.TextInput
                name="email"
                type="email"
                label={t('onboarding.form.email.label')}
                value={values.email}
                errors={translateErrors(errors?.email)}
                onChange={handleChange}
                onBlurCapture={() => {
                  handleFindByEmail(values.email);
                }}
                placeholder={t('onboarding.form.email.placeholder')}
              />
            </div>

            <SignUpExistingAccountWarning
              isVisible={hasExistingAccount && !isAccountWarningDismissed}
              onDismiss={() => setIsAccountWarningDismissed(true)}
              onContinue={onSignIn}
            />
          </div>

          <Form.TextInput
            name="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            errors={translateErrors(errors?.password)}
            label={t('onboarding.form.password.label')}
            placeholder={t('onboarding.form.password.placeholder')}
          />

          <Form.TextInput
            name="passwordConfirmation"
            type="password"
            value={values.passwordConfirmation}
            errors={translateErrors(errors?.passwordConfirmation)}
            onChange={handleChange}
            label={t('onboarding.form.passwordConfirmation.label')}
            placeholder={t('onboarding.form.passwordConfirmation.placeholder')}
          />

          <button
            className="btn btn--primary"
            type="submit"
            disabled={
              isLoading ||
              hasExistingAccount ||
              Object.values(values).includes('')
            }
            data-loading={isLoading}
          >
            <span className="flex items-center justify-center space-x-2">
              {t('onboarding.form.button.register')}
              <IconArrowRight className="w-2 h-2 text-white" />
            </span>
          </button>

          <div className="mt-1 text-center">
            <button
              className="text-brandPrimary hover:underline text-14"
              onClick={onSignIn}
            >
              {t('onboarding.signUp.main.signIn')}
            </button>
          </div>

          <div className="text-12 mt-2 text-center">
            <Trans
              i18nKey="onboarding.form.eula"
              components={{
                /* eslint-disable jsx-a11y/anchor-has-content */
                eulaLink: (
                  <a
                    href="https://www.socialie.com/terms-and-conditions/"
                    className="text-brandPrimary hover:underline"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                ppLink: (
                  <a
                    href="https://www.socialie.com/privacy-policy/"
                    className="text-brandPrimary hover:underline"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                /* eslint-enable jsx-a11y/anchor-has-content */
              }}
            />
          </div>
        </div>
      </Form>
    </>
  );
}
